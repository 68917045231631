@import url(https://fonts.googleapis.com/css?family=Roboto:400,900italic,500,700italic);

body {
	font-family: 'Roboto', 'Open Sans', sans-serif;
	font-size: 13px;
  overflow-x: hidden;
}

button,
input,
optgroup,
select,
textarea {
	font-family: 'Roboto', 'Open Sans', sans-serif;
}

.header {
	background: #fff;
	width: 100%;
}

.logo {
	width: 340px;
	height: 40px;
}

.timer-box {
	padding: 4px;
  background: rgba(245, 250, 250, 0.62);
}

// .timer-box h5 {
	
//   font-size: 40px;
  
// }

.wrap {
	border-radius: 8px;
	padding: 10px;
	width: max-content;
}

.toolbar {
	width: max-content;
	margin: 0 auto 10px;
	background: #000000;
}


button:hover {
	border: 1px solid #a6a6a6;
	color: #777;
}

.countdown {
	width: 100%;
	margin: 0 auto;
}

.bloc-time {
	float: left;
	text-align: center;

}

.count-title {
	display: block;
	margin-bottom: 15px;
	color: #1a1a1a;
	text-transform: uppercase;
}

.figure {
	position: relative;
	float: left;
	height: 110px;
	width: 100px;
	margin-right: 10px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .2), inset 2px 4px 0 0 rgba(255, 255, 255, .08);
}

.section-add .close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
	opacity: 9;
}

.section-add button:hover {
	border: 0px;
	background: #F0F4F7;
	color: #414548;
}

.section-add .nav-pills .nav-link {
	border-radius: 0px;
	background: #fff;
	border: 0px;
	padding: 15px 14px;
	text-align: left;
	font-size: 14px;
}

.section-add .nav-link:focus,
.nav-link:hover {
	background: #F0F4F7;
	color: #414548;
	border-radius: 4px;
}

.section-add button:focus {
	outline: 0px;
}

.section-add .nav-link:focus,
.nav-link:hover {
	border-radius: 4px;
	background: transparent;
	color: #414548;
}

.section-add .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	border-radius: 4px;
	background: #F0F4F7;
	color: #414548;
	padding: 15px 14px;
}

.add-button {
	background: #C3E0FF;
	border: 1px solid #174664;
	color: #000000;
}

.cancel-button {
	background: #F7F4F1;
	color: #000000;
	border: 1px solid #174664;
}

.height-modal-picker {
	min-height: 474px;
}

.button-status {
  position: absolute;
  left: -14px;
  right: 5px;
  width: 100%;
  bottom: 0;
}

.top,
.bottom-back:after {

	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.top {
	z-index: 3;
	border-top-left-radius: 10px;
	background-color: #f7f7f7;
	transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%;
}

.bottom {
	z-index: 1;
}

.bottom:before {
	content: "";
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 50%;
	background-color: rgba(0, 0, 0, .02);
}

#editor {
	margin: 8px 0px;
	border: 1px dashed #000;
	min-height: 100px;
	font-size: 16px;
  background: #fff;
	color: black;
}

:hover[contenteditable] {
	outline: 1px dashed #174664;
}

.wrapper {
	position: relative;
	height: 13em;
	width: 100%;
}

.countdown-container {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	width: 100%;
}

.slider-bar {
	background:#E8F2FF;
}


.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: -16px;
  background-color: #F0F4F7;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav h3 {
	font-weight: 400;
}

.tb-img-view {
	width: 100%;
	display: flex;
	justify-content: center;
}

.tb-container {
	width: 100%;

	position: relative;
}

.tb-container img {
	width: 200px;
	height: auto;
	display: none;
	margin-bottom: 30px;
}

.tb-container input {
	display: none;
}

.tb-container label {
	width: 200px;
	color: #4D4D4D;
	font-size: 15px;
	text-transform: capitalize;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}

.f-14 {
	font-size: 14px;
}
.f-16 {
	font-size: 16px;
}

.f-18 {
	font-size: 18px;
}

.f-20 {
	font-size: 20px;
}

.app-setting {
	visibility: hidden;
}

.app-behav:hover .app-setting {
	visibility: visible;
}

.box-timmer{ 
    margin: 10px; 
    padding: 10px 24px; 
}

.arrow {
	position: absolute;
	left: 0;
	z-index: 1111;
	width: auto;
}

.arrow-right {
	position: absolute;
	right: 0;
	z-index: 1111;
	width: auto;
}

#sidebar-setting {
	width: 17%;
	height: 100vh;
	background: #F0F4F7;
	z-index: 1;
	right: 0;
	position: fixed;
}

#sidebar-timmer {
	width: 17%;
	height: 100vh;
	background: #F0F4F7;
	z-index: 9;
	right: 0;
	position: fixed;
}

#confrence-slider {
	width: 17%;
	height: 100vh;
	background: #F0F4F7;
	z-index: 9;
	right: 0;
	position: fixed;
}

#sidebar-image {
	width: 17%;
	height: 100vh;
	background: #F0F4F7;
	z-index: 9;
	right: 0;
	position: fixed;
}

.toggle-button-cover {
	display: table-cell;
	position: relative;
	width: 68px;
	box-sizing: border-box;
	top: -21px;
}

.icons-slot {
	background: #E5F1FA;
}


.button-cover,
.knobs,
.layer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.button-on {
	position: relative;
	top: 50%;
	width: 70px;
	border: 5px solid #fff;

}

.button-on.r,
.button-on.r .layer {
	border-radius: 100px;
}

.button-on.b2 {
	border-radius: 2px;
}

.checkbox {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
	cursor: pointer;
	z-index: 3;
}

.knobs {
	z-index: 2;
}

.layer {
	width: 100%;
	background-color: #E7EFF4;
	transition: 0.3s ease all;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	z-index: 1;
}

/* Button 1 */
#button-1 .knobs:before {
	content: 'OFF';
	position: absolute;
	top: -2px;
	left: 1px;
	width: 31px;
	height: 25px;
	color: #fff;
	font-size: 10px;
	text-align: center;
	line-height: 0.6;
	padding: 11px 4px;
	background-color: #8E9AAA;
	border-radius: 50%;
	transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked+.knobs:before {
	content: 'ON';
	left: 30px;
	background-color: #359A4B;
}

#button-1 .checkbox:checked~.layer {
	background-color: #ACD0B4;
}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
	transition: 0.3s ease all;
}

.action-button {
	bottom: 7px;
	right: 7px;
}

.action-button .cancel {
	background: #fff;
	border: 1px solid #174664;
}

.action-button .save {
	background: #6B9EBA;
	color: #fff;
}

.col-image-frame {
	min-height: 544px;
}

#sidebar-image .nav-pills .nav-link {
	border: 0px;
	padding: 7px 60px;
}

button:focus {
	outline: 0px;
}

#sidebar-image .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	border-radius: 20px;
	padding: 7px 57px;
}

.button-add-section {
	background: #C3E0FF;
	border: 1px solid #174664;
}

.about-us {
background:url('../images/banner.png') no-repeat center center fixed;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
width: 100%;
background-position: bottom;
background-size: cover;
}




.f-24{font-size: 24px;}
.overlay{background: #ebebebf7; width: 100%;}
.corporate-addetndes{ background: #E8F2FF;}
.border-media{    border: 1px dashed #4757f2;
  min-height: 240px;
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  margin-top: 12px;
    align-items: center;
  padding: 10px;}

  .border-media img{margin: 0 auto;}  
 

  @media(max-width:1440px){
	.texting.col-5 {
		flex: 0 0 58.666667%;
		max-width: 58.666667%;
	}
  }
//   @media (min-width: 1200px) {
//     .container {
//         max-width: 90%!important;
//     }
// } 

.up-down-section{ z-index: 1;
  width: 100%;
  right: 0;
  left: 0;
  text-align: right;}

  .overlay:hover .up-down-section{visibility: visible;}
  .up-down-section{visibility: hidden;}

  .box-modal {
	position:fixed;
	width:100%;
	height: 80%;
	left:0px;
	top:31px;
	visibility:hidden;}


	.overlay-components {
	  position:absolute;
	  width:100%;
	  height:100%;
	  left:0px;
	  top:0px;
	  background:#000;
	 opacity: 0.8;
	  transition:opacity 1.3s;
	}
	
	.body-modal {
	 
	  opacity:0;
	  overflow:hidden;}

	  .inner-body-modal {
		font-size: 24px;
		padding:25px;
		border-radius: 8px;
		position:relative;
		z-index:1;
		color: #fff;
		box-shadow:0px 0px 65px 0px rgba(0, 0, 0, 0.43);
	  }
	
	
	 .modal-show {
	  display:flex;
	  align-items:center;
	  justify-content:center;
	   visibility:visible;
	   transition:all 0.9s;}

	  .overlay-components {
		opacity:0.8;
		transition:opacity 1.3s;
	  }
	   
	   .body-modal {
		 opacity:1;
		 transition:0.1s max-height 0.2s, 0.1s opacity 0.2s, ;
	   }
	