@import "@progress/kendo-theme-default/dist/all.scss";
:root {
    --kendo-color-primary-subtle: #ffeceb;
    --kendo-color-primary-subtle-hover: #dcdbff;
    --kendo-color-primary-subtle-active: #c4cfff;
    --kendo-color-primary: #115ab9;
    --kendo-color-primary-hover: #115ab9;
    --kendo-color-primary-active: #115ab9;
    --kendo-color-primary-emphasis: #115ab9;
    --kendo-color-primary-on-subtle: #115ab9;
    --kendo-color-on-primary: #ffffff;
    --kendo-color-primary-on-surface: #115ab9;
    // Configure more swatch colors
    // ...
    }
/* You can add global styles to this file, and also import other style files */


.toast-controller {
    margin: 10px;
    min-width: 200px;
}

.cursor-pointer {
    cursor: pointer;
}

.set-size-15 {
    width: 15px;
    height: 15px;
}
.form-error {
    font-size: 12px;
    color: #B94A48;
}

.k-list-item.k-selected, .k-selected.k-list-optionlabel {
    background-color: #115ab9 !important;  /* Ensure selected option has the same background */
    color: white !important;  /* Optionally change text color */
}
.k-list .k-list-item:hover {
    background-color: #115ab9;  /* Custom hover effect */
    color: white;  /* Change text color on hover */
}
::ng-deep .k-daterangepicker .k-calendar .k-state-selected {
    background-color: #2ecc71 !important; /* Green background for selected dates */
    color: white !important; /* White text on selected dates */
}

/* Change the background color of a date when hovered */
::ng-deep .k-daterangepicker .k-calendar .k-state-hover {
    background-color: #3498db !important; /* Blue background on hover */
    color: white !important; /* Text color on hover */
}

/* Change the background color of today's date */
::ng-deep .k-daterangepicker .k-calendar .k-state-today {
    background-color: #f39c12 !important; /* Yellow background for today's date */
    color: white !important;  /* Text color for today's date */
}

.itinerary-popup-class {
    width: 500px;
}
.k-animation-container {
    z-index: 999;
}