$spacing-sm: 6px;
$spacing-md: 12px;
$spacing-xl: 24px;
$spacing-xxl: 30px;
$spacing-lg: 48px;
$spacing-double-xl: 70px;
$list-container-shadow: 0 5px 10px 0 rgba(0, 0, 0, .06) !default;
$base-bg: #ffffff !default;
$a2b-primary-lighter:#008eee;
$a2b-burnt-sienna: #eb5757;

*,
body {
    font-family: 'Roboto', 'Open Sans', sans-serif;
}
.f-600 {
    font-weight: 600;
}
.bold {
    font-weight: bold;
}
.font-14 {
    font-size: 14px;
}

a.navbar-brand {
    color: #000;
    font-size: 30px;
    font-weight: 700;
}

input:focus,
.btn:focus,
.nav-link:focus,
button:focus {
    box-shadow: none !important;
    outline: none;
}

.navbar-nav a.nav-link {
    color: #000 !important;
    font-size: 16px;
    font-weight: 500;
}

.container {
    max-width: 75% !important;
}

 .navbar {
    background-color: #fff;
 }

.footer {
    background: #000;
    position:fixed;
}

.mainDev {
    border-radius: 25px;
    box-shadow: 0px 4px 40px 0px rgba(12, 96, 178, 0.20);
}

.mainDev h4,
.listing h4 {
    color: #115AA8;
    font-size: 28px;
    font-weight: 700;
}

.form-text {
    color: #0759AA;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.btn-light {
    font-weight: 500;
}

.form-control,
.listing {
    border-radius: 5px;
    border: 1px solid #E5EEF4;
    background: #F4F7F9;
}

.navbar-brand1 {
    color: #B5A599 !important;
    font-size: 41.354px;
}

.nav-pills .nav-link.active {
    background-color: #0759AA;
}

.nav-pills .nav-link {
    border: 1px solid #115AA8;
    background-color: #fff;
    color: #292929;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    cursor: pointer;
    padding: 12px 30px !important;
}

.nav-pills .nav-link.active .cmsIcon {
    background-position: -12px -45px;
}

.nav-pills .nav-link.active .roadshows-icon {
    background-position: -78px -46px;
}

.nav-pills .nav-link.active .events-icon {
    background-position: -149px -47px;
}

.listing h6 {
    font-weight: 700;
}

.cmsIcon {
    background-image: url('../img/sprite-image.svg');
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
    display: inline-block;
    background-position: -14px -11px;
}

.roadshows-icon {
    background-position: -80px -11px;
}

.events-icon {
    background-position: -151px -11px;
}

.location {
    background-position: -256px -9px;
}

.date {
    background-position: -221px -9px;
}

.borderRight {
    border-right: 1px solid #E5EEF4;
}

.email-icon {
    background-position: -214px -160px;
}

.interested-icon {
    background-position: -17px -116px;
}

.interest-icon {
    background-position: -65px -116px;
}

.interested-icon:hover,
.selected {
    background-position: -17px -158px;
}

.interest-icon:hover, .dislike {
    background-position: -65px -158px;
}

.registration-icon {
    background-position: -171px -160px;
}

.itinerary-icon {
    background-position: -257px -162px;
}

.listing {
    background-color: #F7FBFF;
}

.bg-upcoming {
    
    top: -31px;
    width: auto;
    font-size: 14px;

}

.bg-running {
    background: #A6F972;
    color: #326500;
}

.text-blue {
    color: #115AA8;
}

.flag_icon {
    background-position: -134px -154px;
}

span.cmsIcon.dropdown_icon {
    background-position: -330px -8px;
}

.dateSelect {
    background-position: -298px -8px;
    top: 0px;
    right: 0px;

}

.detailsDiv label {
    color: #292929 !important;
}

.detailsDiv .btn-primary {
    background-color: #115AA8 !important;
}

.nav-pills .nav-link.active .cmsIcon.basic-icon {
    background-position: -232px -110px;
}

span.basic-icon {
    background-position: -232px -71px;
}

.nav-pills .nav-link.active .cmsIcon.availability-icon {
    background-position: -287px -111px;
}

.availability-icon {
    background-position: -288px -71px;
}

.nav-pills .nav-link.active .cmsIcon.preferences-icon {
    background-position: -342px -111px;
}

span.cmsIcon.preferences-icon.mr-2 {
    background-position: -341px -71px;
}

.detailsDiv {
    overflow: auto;
    max-height: 512px;
}

.slick-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 10px
}

.slick-scrollbar::-webkit-scrollbar-track {
    background-color: #d1d9e0 !important;
    border-radius: 8px
}

.slick-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 2.5px;
    background: #2171b5;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    border-radius: 8px
}

.slick-scrollbar::-webkit-scrollbar-thumb:horizontal:hover,
.slick-scrollbar::-webkit-scrollbar-thumb:vertical:hover {
    background: #2171b5;
    width: 10px
}

.timeBlock {
    background-color: #ECF7FE;
}

.timeBlock h6 {
    font-size: 14px;
}

.bg-blue {
    background-color: #115AA8;
}

.timeBlockCard .card-header {
    border-radius: 10px 10px 0px 0px;
}

.timeBlockCard {
    border: 1px solid #E5EEF4;
    border-radius: 10px 10px 10px 10px;
}

.opacity-60 {
    opacity: 0.60;
}

.listing:hover {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(12, 96, 178, 0.25);
}

.btnactive {
    border-radius: 5px 0px 0px 5px;
    background: linear-gradient(85deg, #095CAD -0.34%, #3899F8 101.57%) !important;
}

.btn-none-active{
    border: 1px solid #3291EF;
}
.uploadBtn {
    right: 64px;
    top: 14px;
}
.detailsDiv p.text-secondary.my-xl-4{
    font-size: 14px;
}
/* checkbox css start  */
.reportsRadio .text-dark{
    line-height: 27px;
}
.reportsRadio input[type='radio'] {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #99A3AA;
    border-radius: 50%;
    outline: none;
}

.reportsRadio input[type='radio']:before {
    content: '';
    display: block;
    width: 50%;
    height: 50%;
    margin: 24% auto;
    border-radius: 50%;
}

.reportsRadio input[type='radio']:checked:before {
    background: #fff;
}

.reportsRadio input[type='radio']:checked {
    border-color: #0759AA;
    background: #0759AA;
}

.reportsRadio {
    color: #99A3AA;
}
.bg-cover-image{
 position: relative;
}
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

section.bg-cover-image:before {
    content: "";
    background: linear-gradient(2deg, #85b8f5 -3.28%, #FFF 129.21%);
    filter: blur(62px);
    position: absolute;
    left: 0px;
    bottom: 145px;
    transform: rotate(-23deg);
    padding: 0px 34.9px 346.847px 12.5px;
    height: 70%;
    width: 70px;
}

.loader-content {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .loader-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    
    ::ng-deep .k-loading-image {
      bottom: 0 !important;
      top: auto !important;
    }
    ::ng-deep .k-loading-image::before, .k-loading-image::after {
      font-size: 2em;
    }
  }
  
 

@media(max-width:1536px) {
    .detailsDiv {
        padding: 0 0 !important;
    }
}

@media(max-width:1440px) {
    .listing p {
        font-size: 14px;
    }
    .reportsRadio .text-dark {
        font-size: 14px;
    }
}

@media (max-width:1024px) {
    .btn-group.rounded button {
        font-size: 14px;
        padding: 8px 8px !important;
    }
    .uploadBtn {
        right: 0px;
        top: 14px;
    }
    .detailsDiv label {
        font-size: 14px;
    }

    .detailsDiv h5 {
        font-size: 16px;
    }

    a.navbar-brand {
        font-size: 18px;
        white-space: normal;
    }

    button.btn.btn-primary.ml-auto.rounded-pill {
        margin: auto;
    }

    .login-form .mx-5 {
        margin: 0 30px !important;
    }

    .login-form {
        padding: 30px 0 !important;
    }

    .mainDev h4 {
        font-size: 20px;
    }

    label.h6.text-secondary {
        font-size: 14px;
    }
}

@media(max-width:767px) {
        a.navbar-brand {
        width: 79%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .nav-pills .nav-link {
        font-size: 12px;
        padding: 7px 20px !important;
        line-height: 24px;
    }
    .container {
        max-width: 100% !important;
    }
}
@media(max-width:500px) {
    .reportsRadio .text-dark {
        font-size: 13px;
        padding-right: 20px;
    }
    .uploadBtn {
        right: 12px;
        top: 64px;
    }
}


@media(max-width: 1200px) {
    .container{max-width: 95%!important;}
  }
  @media(max-width: 1366px) {
    .container{max-width: 95%!important;}
  }

.k-notification.a2b-toast-notification {
    border-radius: 5px;
    padding: $spacing-md;
    margin-right: $spacing-md;
    box-shadow: $list-container-shadow;
  
    &:not(.auto-width) {
      width: 250px;
    }
  
    &.longer-toast {
      width: 505px;
    }
  
    &.k-notification-error {
      background-color: $a2b-burnt-sienna;
      border-color: $a2b-burnt-sienna;
    }

    &.k-notification-success {
        background-color: #66BE79 ;
        border-color: #66BE79 ;
    }
  
    &.k-notification-info {
      background-color: $a2b-primary-lighter;
      border-color: $a2b-primary-lighter;
    }
  
    .k-notification-wrap {
      align-items: center;
  
      .k-i-error,
      .k-i-success,
      .k-i-info,
      .k-i-warning {
        padding-right: $spacing-sm;
        color: $base-bg;
      }
  
      .k-notification-content {
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $base-bg;
      }
    }
  
    .k-icon {
      font-family: 'WebComponentsIcons';
    }
  }